import React, { useEffect, useRef, useState } from "react";
import { Carousel } from "react-carousel-minimal";
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa";
import { Tab, Col, Nav, Form } from "react-bootstrap";
import elexModalBannernew from "../elexModalBannernew.jpg";
import { Navigate, useNavigate } from "react-router-dom";

const ExchangeCarousel = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState("first");

  const modalRef = useRef(null);

  // const handleClickOutside = (event) => {
  //   if (modalRef.current && !modalRef.current.contains(event.target)) {
  //     setIsOpen(false);
  //   }
  // };
  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside);
  //   return () => document.removeEventListener("click", handleClickOutside);
  // }, []);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const navigate = useNavigate();

  const handleTabSelect = (eventKey) => {
    setCurrentTab("second");
  };

  const data = [
    // {
    //   image: "assets/img/banner-4/elex-banner-new.jpg",
    // },
    // {
    //   image: "assets/img/banner-4/elex-banner-new1.jpg",
    // },
    {
      image: "assets/img/banner-4/elex-banner-lg.jpg",
    },
    // {
    //   image: "assets/img/banner-4/CG-Elex-Beko-2081.jpg",
    // },
    // {
    //   image: "assets/img/banner-4/MideaBanner-2081.jpg",
    // },
    // {
    //   image: "assets/img/banner-4/CG-Elex-2081.jpg",
    // },
    // {
    //   image: "assets/img/banner-4/CG-Elex-SDA-2081.jpg",
    // },
    // {
    //   image: "assets/img/banner-4/banner-new-year.jpg",
    // },
    // {
    //   image: "assets/img/banner-4/re-elex-banner-1.jpg",
    // },
    {
      image: "assets/img/banner-4/re-elex-banner-2.jpg",
    },
    {
      image: "assets/img/banner-4/re-elex-banner-3.jpg",
    },
    {
      image: "assets/img/banner-4/CG-Elex1.jpg",
    },
    {
      image: "assets/img/banner-4/CG-Elex2.jpg",
    },
  ];

  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
  };

  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };

  return (
    <div ref={modalRef} className="main-carousel">
      <Modal
        ref={modalRef}
        isOpen={modalIsOpen}
        onClose={handleClose}
        className="mobileko"
      >
        <div style={{ position: "relative", cursor: "pointer" }}>
          <div
            style={{
              border: 0,
              borderRadius: "20px",
              zIndex: 99999,
              backgroundColor: "#f0ece4",
              padding: "3.125rem",
            }}
          >
            <span style={{ fontSize: "2.25rem", lineHeight: "2.625rem" }}>
              Installment Calculator
            </span>
            <hr />
            <p>
              <span
                style={{
                  fontSize: "1.25rem",
                  lineHeight: "1.5rem",
                  padding: ".75rem 0",
                  color: "#000",
                }}
              >
                Product Cost
              </span>
              :
              <span
                style={{
                  fontSize: "1.25rem",
                  lineHeight: "1.5rem",
                  padding: ".75rem 0",
                  color: "#000",
                }}
              >
                ₹25999
              </span>
            </p>
            <Tab.Container
              defaultActiveKey="first"
              activeKey={currentTab}
              onSelect={(key) => setCurrentTab(key)}
              className="nav tabs"
            >
              <Col className="">
                <Nav variant="pills" className="tab prd-desc" align="left">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="first"
                      className="modaltabbs"
                      style={{
                        padding: ".75rem .625rem",
                      }}
                    >
                      Credit Card
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second" className="modaltabbs">
                      Debit Card
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Tab.Content>
                <Tab.Pane
                  onClick={handleTabSelect}
                  eventKey="first"
                  style={{
                    marginTop: "10px",
                    background: "#fff",
                    animationDuration: "0.5s",
                    overflowY: "auto",
                    scrollbarWidth: "thin",
                    height: "40vh",
                    backgroundColor: "#f0ece4",
                  }}
                >
                  <div>
                    <form
                      style={{
                        borderRadius: "1.75rem",
                        padding: ".75rem",
                        backgroundColor: "#e6e1d6",
                      }}
                    >
                      <label for="banks">Select a bank:</label>
                      <br />
                      <select
                        id="banks"
                        name="banks"
                        style={{
                          borderColor: "#cbc8c2",
                          padding: "10px",
                          borderRadius: "10px",
                          width: "100%",
                        }}
                      >
                        <option value="Nabil Bank">Nabil Bank</option>
                        <option value="HBL">Himalayan Bank Limited</option>
                      </select>
                    </form>
                    <hr />
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      No Cost EMI
                    </p>
                    <table class="table table-bordered  table-striped mb-5">
                      <thead>
                        <tr>
                          <th>EMI Plan</th>
                          <th>Interest(pa)</th>
                          <th>EMI Discount</th>
                          <th>Total Payable</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>₹4748 x 6m</td>
                          <td>₹1206 (15%)</td>
                          <td>₹1206</td>
                          <td>₹28490</td>
                        </tr>
                        <tr>
                          <td>₹4748 x 6m</td>
                          <td>₹1206 (15%)</td>
                          <td>₹1206</td>
                          <td>₹28490</td>
                        </tr>
                        <tr>
                          <td>₹4748 x 6m</td>
                          <td>₹1206 (15%)</td>
                          <td>₹1206</td>
                          <td>₹28490</td>
                        </tr>
                        <tr>
                          <td>₹4748 x 6m</td>
                          <td>₹1206 (15%)</td>
                          <td>₹1206</td>
                          <td>₹28490</td>
                        </tr>
                      </tbody>
                    </table>
                    <hr />
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Standard EMI
                    </p>
                    <table class="table table-bordered  table-striped">
                      <thead>
                        <tr>
                          <th>EMI Plan</th>
                          <th>Interest(pa)</th>
                          <th>EMI Discount</th>
                          <th>Total Payable</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>₹4748 x 6m</td>
                          <td>₹1206 (15%)</td>
                          <td>₹1206</td>
                          <td>₹28490</td>
                        </tr>
                        <tr>
                          <td>₹4748 x 6m</td>
                          <td>₹1206 (15%)</td>
                          <td>₹1206</td>
                          <td>₹28490</td>
                        </tr>
                        <tr>
                          <td>₹4748 x 6m</td>
                          <td>₹1206 (15%)</td>
                          <td>₹1206</td>
                          <td>₹28490</td>
                        </tr>
                        <tr>
                          <td>₹4748 x 6m</td>
                          <td>₹1206 (15%)</td>
                          <td>₹1206</td>
                          <td>₹28490</td>
                        </tr>
                      </tbody>
                    </table>
                    <hr />
                    <form
                      className="mb-5"
                      style={{
                        borderRadius: "1.75rem",
                        padding: ".75rem",
                        backgroundColor: "#e6e1d6",
                      }}
                    >
                      <label for="banks">Select a bank:</label>
                      <br />
                      <select
                        id="banks"
                        name="banks"
                        style={{
                          borderColor: "#cbc8c2",
                          padding: "10px",
                          borderRadius: "10px",
                          width: "100%",
                        }}
                      >
                        <option value="Nabil Bank">Nabil Bank</option>
                        <option value="HBL">Himalayan Bank Limited</option>
                      </select>
                    </form>
                    <hr />
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      No Cost EMI
                    </p>
                    <table class="table table-bordered  table-striped mb-5">
                      <thead>
                        <tr>
                          <th>EMI Plan</th>
                          <th>Interest(pa)</th>
                          <th>EMI Discount</th>
                          <th>Total Payable</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>₹4748 x 6m</td>
                          <td>₹1206 (15%)</td>
                          <td>₹1206</td>
                          <td>₹28490</td>
                        </tr>
                        <tr>
                          <td>₹4748 x 6m</td>
                          <td>₹1206 (15%)</td>
                          <td>₹1206</td>
                          <td>₹28490</td>
                        </tr>
                        <tr>
                          <td>₹4748 x 6m</td>
                          <td>₹1206 (15%)</td>
                          <td>₹1206</td>
                          <td>₹28490</td>
                        </tr>
                        <tr>
                          <td>₹4748 x 6m</td>
                          <td>₹1206 (15%)</td>
                          <td>₹1206</td>
                          <td>₹28490</td>
                        </tr>
                      </tbody>
                    </table>
                    <hr />
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Standard EMI
                    </p>
                    <table class="table table-bordered  table-striped">
                      <thead>
                        <tr>
                          <th>EMI Plan</th>
                          <th>Interest(pa)</th>
                          <th>EMI Discount</th>
                          <th>Total Payable</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>₹4748 x 6m</td>
                          <td>₹1206 (15%)</td>
                          <td>₹1206</td>
                          <td>₹28490</td>
                        </tr>
                        <tr>
                          <td>₹4748 x 6m</td>
                          <td>₹1206 (15%)</td>
                          <td>₹1206</td>
                          <td>₹28490</td>
                        </tr>
                        <tr>
                          <td>₹4748 x 6m</td>
                          <td>₹1206 (15%)</td>
                          <td>₹1206</td>
                          <td>₹28490</td>
                        </tr>
                        <tr>
                          <td>₹4748 x 6m</td>
                          <td>₹1206 (15%)</td>
                          <td>₹1206</td>
                          <td>₹28490</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="second"
                  style={{
                    marginTop: "10px",
                    background: "#fff",
                    animationDuration: "0.5s",
                  }}
                >
                  <p>This is a test 2</p>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
        <FaTimes
          onClick={handleClose}
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
        />
      </Modal>
      {/* <button className="button" onClick={handleOpen}>
        Button Name
      </button> */}
      <div style={{ textAlign: "center" }}>
        <div>
          <Carousel
            data={data}
            time={3000}
            width="100%"
            height="500px"
            captionStyle={captionStyle}
            radius="10px"
            slideNumber={false}
            slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            automatic={true}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="darkgrey"
            slideImageFit="contain" // Added this
            // initialSlideHeight={500}
            thumbnails={false}
            showThumbs={false}
            thumbnailWidth="100px"
            style={{
              textAlign: "center",
              maxWidth: "100%",
              maxHeight: "100%",
              margin: 0,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ExchangeCarousel;
