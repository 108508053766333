import React from "react";
import {
  FaChevronRight,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import newlogo from "../newlogo.png";

const FooterThree = () => {
  return (
    <>
      {/* ================== Footer Two Start ==================*/}
      <footer className="footer-area bg-black mt-0 pd-top-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="widget widget_about">
                <div className="thumb">
                  {/* <h2 style={{ color : "#fff" }}>CG Elex</h2> */}
                  <img src={newlogo} alt="Logo" />
                </div>
                <div className="details">
                  <p>
                    Upgrade your lifestyle and step-in into the world of CGELEX.
                  </p>
                  {/* <ul className='social-media'>
                    <li>
                      <a href='#'>
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaYoutube />
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Quick Links</h4>
                <ul>
                  <li>
                    <a
                      onClick={() => {
                        window.location.href = "/";
                      }}
                      className="main_navs"
                    >
                      <FaChevronRight /> Home
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        window.location.href = "/about";
                      }}
                      className="main_navs"
                    >
                      <FaChevronRight /> About Us
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        window.location.href = "/exchange";
                      }}
                      className="main_navs"
                    >
                      <FaChevronRight /> Exchange
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        window.location.href = "/store";
                      }}
                      className="main_navs"
                    >
                      <FaChevronRight /> Store
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        window.location.href = "/contact";
                      }}
                      className="main_navs"
                    >
                      <FaChevronRight /> Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* <div className='col-xl-3 col-lg-3 col-md-6'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>Support</h4>
                <ul>
                  <li>
                  <Link to='#'>
                      <FaChevronRight /> 211 Service
                    </Link>
                  </li>
                  <li>
                  <Link to='#'>
                      <FaChevronRight /> Service Centers Locator
                    </Link>
                  </li>
                  <li>
                  <Link to='#'>
                      <FaChevronRight /> Register a Product
                    </Link>
                  </li>
                  <li>
                  <Link to='#'>
                      <FaChevronRight /> Track Repair Status
                    </Link>
                  </li>
                  <li>
                    <Link to='#'>
                      <FaChevronRight /> Store Consultation
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            
            <div className='col-xl-3 col-lg-3 col-md-6'>
              <div className='widget widget_about'>
                <h4 className='widget-title'>Newsletter</h4>
                <div className='details'>
                  <p>
                    Subscribe to your newsletter.
                  </p>
                  <div className='subscribe mt-4'>
                    <input type='text' placeholder='E-mail' />
                    <button>
                      <FaChevronRight />
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="footer-bottom bg-gray-black">
          <div className="container">
            <div className="row">
              <div className="col-md-6 align-self-center">
                <p>© CG Elex 2023 | All Rights Reserved</p>
              </div>
              <div className="col-md-6 text-lg-end">
                <a href="/terms-and-conditions">Terms &amp; Condition</a>
                <a href="/privacy-policy">Privacy Policy</a>
                {/* <a href='#'>Contact Us</a> */}
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* ================== Footer Two  end ==================*/}
    </>
  );
};

export default FooterThree;
